import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { IUserInfo } from '@core/models/auth/IDecodedToken.interface';
import { UserStore } from '@core/store/user.store';
import { LocalStorageService } from '../auth/local-storage.service';
import { LoginService } from '../auth/login.service';
import { environment } from '@envs/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private httpClient = inject(HttpClient);
  private translateService = inject(TranslateService);

  private localStorageService = inject(LocalStorageService);
  private userStore = inject(UserStore);
  private loginService = inject(LoginService);

  private defaultLang = 'es';

  constructor() {

     this.initLang();

  }

  initLang() {
    const savedLang = this.localStorageService.getLang();
    if (savedLang) {
      this.translateService.use(savedLang);
    } else {
      this.translateService.use(this.defaultLang);
    }
  }

  changeLang(lang: string) {
    this.translateService.use(lang);
    this.localStorageService.setLang(lang);
  }

  setLanguage(language: string) {
    this.httpClient.patch<IUserInfo>(environment.API_BASE_URL + 'users/update', { language }).pipe(
      catchError((err) => {
        console.error('Error:', err);
        return throwError(() => err);
      })
    ).subscribe((res) => {
      this.changeLang(res.language.split('_')[0].toLocaleLowerCase());
      this.userStore.setUserLanguage(language);
      this.loginService.refreshToken().subscribe(
        (res) => {
          this.localStorageService.setTokens(res.token, res.refresh_token);
        }
      );
    });
  }
}
