import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginReq } from '@core/models/auth/ILoginReq.interface';
import { ILoginRes } from '@core/models/auth/ILoginRes.interface';
import { environment } from '@envs/environment';
import { LocalStorageService } from './local-storage.service';
import { UserStore } from '@core/store/user.store';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private httpClient = inject(HttpClient);
  private router = inject(Router);

  private localStorageService = inject(LocalStorageService);

  private readonly userStore = inject(UserStore);

  constructor() {}

  /**
   * ****************************************************
   * Login State Logic
   * ****************************************************
   */

  setUserInfo(token: string, refresh_token: string) {
    this.localStorageService.setTokens(token, refresh_token);

    const payload = this.localStorageService.decodeToken(token);

    this.userStore.setUserInfo(payload.userInfo);
  }

  logout() {
    this.localStorageService.removeTokens();
    this.router.navigate(['/login']);
    this.userStore.resetUserInfo();
  }

  /**
   * ****************************************************
   * API calls
   * ****************************************************
   */

  getTokens(loginForm: ILoginReq) {
    return this.httpClient.post<ILoginRes>(
      environment.API_BASE_URL + 'users/login',
      loginForm
    );
  }

  refreshToken() {
    const refreshToken = this.localStorageService.getRefreshToken();

    const headers = new HttpHeaders({
      'X-Skip-Interceptor': 'true'
    });

    return this.httpClient.post<ILoginRes>(
      environment.API_BASE_URL + 'users/refresh-token',
      { refresh_token: refreshToken },
      { headers }
    );
  }
}
